// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCg_loc3CEPSbnydMqZvN-Cwt19z-Q6y18",
  authDomain: "ajutorescu.firebaseapp.com",
  projectId: "ajutorescu",
  storageBucket: "ajutorescu.appspot.com",
  messagingSenderId: "571159076946",
  appId: "1:571159076946:web:7e4f71a054cfd3e3c17f8b",
  measurementId: "G-GLF73RNKDF",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const addEmailToFirestore = async (name, email) => {
  try {
    const docRef = await addDoc(collection(db, "emails"), {
      name: name,
      email: email,
      timestamp: new Date(),
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export { addEmailToFirestore };
