import React, { useState } from "react";
import { addEmailToFirestore } from "../data/firebase";
import "../styling/landing.css";
import Logo from "../assets/logo.png";

const Landing = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", { name, email });
    try {
      await addEmailToFirestore(name, email);
      setMessage("Thank you! We will notify you when the site is online.");
      setName("");
      setEmail("");
    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage("Failed to submit form. Please try again.");
    }
  };

  return (
    <div className="landing-container">
      <div className="background">
        <img className="logo-homepage" src={Logo} alt="" />
        <h1 className="text-landing-title">Stay Updated!</h1>
        <h2 className="text-landing">
          Lăsați-ne adresa de email și vă vom anunța când site-ul va fi online,
          oferindu-vă și un discount la primele 5 servicii!
        </h2>
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="buttons-container">
            <input
              type="text"
              value={name}
              className="input-style"
              onChange={(e) => setName(e.target.value)}
              placeholder="Numele"
              required
            />
            <input
              type="email"
              value={email}
              className="input-style"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Adresa de email"
              required
            />{" "}
            <button type="submit" className="button-submit">
              Trimite
            </button>
          </div>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};
export default Landing;
