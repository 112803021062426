// src/App.js
import React from "react";
import Landing from "./pages/Landing.jsx";

const App = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}>
      <Landing />
    </div>
  );
};

export default App;
